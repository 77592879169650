@import "~@/styles/variables.scss";










































































































































































































































































































































































































































































































































































































.el-steps {
    margin: 15px 0;
}

.el-tab-pane {
    margin-right: 25px;
}

.el-step_title {
    font-size: 13px;
}

.img {
    width: 100%;
    max-width: 250px;
}

